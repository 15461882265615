import SimpleSchema from 'simpl-schema';
import {
  validation,
  AddressSchema,
  EmailSchema,
  PhoneSchema,
  Honeypot
} from 'api/schemas/_Global';

const ContactInformationSchema = new SimpleSchema({
  name: {
    type: String,
    regEx: validation.name,
    max: 255,
    label: 'Name'
  },
  jobTitle: {
    type: String,
    regEx: validation.name,
    max: 255,
    label: 'Job Title',
    optional: true
  },
  company: {
    type: String,
    label: 'Company',
    regEx: validation.name,
    max: 50,
    optional: true
  }
}).extend(EmailSchema).extend(Honeypot);

const BaseContactFormSchema = new SimpleSchema({
  inquiry: {
    type: String,
    label: 'Inquiry',
    max: 500
  }
}).extend(ContactInformationSchema);

export const ContactFormSchema = new SimpleSchema({
  subject: {
    type: String,
    label: 'Contact Reason',
    max: 140,
    uniforms: {
      options: () => {
        return [
          { label: `General Inquiry`, value: `General Inquiry` },
          { label: `Sales Inquiry`, value: `Sales Inquiry` },
          { label: `PR`, value: `PR` },
          { label: `Partners / Resellers`, value: `Partners / Resellers` },
          { label: `Jobs`, value: `Jobs` },
          { label: `Other`, value: `Other` }
        ];
      }
    }
  }
}).extend(BaseContactFormSchema);

export const SalesContactFormSchema = new SimpleSchema({
  products: {
    type: Array,
    label: 'Product Interest'
  },
  'products.$': {
    type: String,
    uniforms: {
      checkboxes: true,
      options: () => {
        return [
          {
            label: `Multiviewers `,
            value: `Multiviewers `
          },
          {
            label: `Extenders`,
            value: `Extenders`
          },
          {
            label: `Repeaters`,
            value: `Repeaters`
          },
          {
            label: `Splitters`,
            value: `Splitters`
          },
          {
            label: `Switches `,
            value: `Switches `
          },
          {
            label: `Distribution Amplifiers`,
            value: `Distribution Amplifiers`
          },
          {
            label: `Converters `,
            value: `Converters `
          }
        ];
      }
    }
  },
  market: {
    type: String,
    label: 'Market',
    max: 140,
    uniforms: {
      options: () => {
        return [
          { label: `Broadcast`, value: `Broadcast` },
          { label: `Education`, value: `Education` },
          { label: `Houses of Worship`, value: `Houses of Worship` },
          { label: `Government`, value: `Government` },
          { label: `Security`, value: `Security` },
          { label: `Entertainment`, value: `Entertainment` },
          { label: `Other`, value: `Other` }
        ];
      }
    }
  },
  referral: {
    type: String,
    label: 'I heard about Apantac from...',
    max: 140,
    optional: true
  }
}).extend(BaseContactFormSchema).extend(PhoneSchema);

export const SupportContactFormSchema = new SimpleSchema({
  subject: {
    type: String,
    label: 'Subject',
    max: 140
  },
  product: {
    type: String,
    label: 'Product',
    max: 140
  }
}).extend(BaseContactFormSchema).extend(PhoneSchema);

export const ResellerContactFormSchema = new SimpleSchema({
  products: {
    type: Array,
    label: 'Product Interest'
  },
  'products.$': {
    type: String,
    uniforms: {
      checkboxes: true,
      options: () => {
        return [
          {
            label: `Multiviewers `,
            value: `Multiviewers `
          },
          {
            label: `Extenders`,
            value: `Extenders`
          },
          {
            label: `Repeaters`,
            value: `Repeaters`
          },
          {
            label: `Splitters`,
            value: `Splitters`
          },
          {
            label: `Switches `,
            value: `Switches `
          },
          {
            label: `Distribution Amplifiers`,
            value: `Distribution Amplifiers`
          },
          {
            label: `Converters `,
            value: `Converters `
          }
        ];
      }
    }
  },
  website: {
    type: String,
    label: 'Website',
    regEx: SimpleSchema.RegEx.Url,
    optional: true
  }
}).extend(BaseContactFormSchema).extend(AddressSchema).extend(PhoneSchema);

export const RMAContactFormSchema = new SimpleSchema({
  reseller: {
    type: String,
    label: 'Reseller',
    max: 140
  },
  ref: {
    type: String,
    label: `PO, SO, or Ref #'s:`,
    max: 140
  },
  purchaseDate: {
    type: Date,
    label: 'Purchase Date'
  },
  reason: {
    type: String,
    label: 'Reason for Return',
    uniforms: {
      options: () => {
        return [
          {
            label: `Repair of Equipment.`,
            value: `Repair of Equipment.`
          },
          {
            label: `Return of Demo/Loaned Equipment.`,
            value: `Return of Demo/Loaned Equipment.`
          },
          {
            label: `Refund (Must be within 45 days of purchase. Restocking fees may apply.)`,
            value: `Refund (Must be within 45 days of purchase. Restocking fees may apply.)`
          }
        ];
      }
    }
  },
  serialNumbers: {
    type: String,
    label: 'Serial Numbers',
    max: 140
  },
  faultDescription: {
    type: String,
    label: 'Fault Description',
    max: 500
  },
  comments: {
    type: String,
    label: 'Comments',
    max: 500,
    optional: true
  }
}).extend(ContactInformationSchema).extend(AddressSchema).extend(PhoneSchema);

export const PRContactFormSchema = new SimpleSchema({
  subject: {
    type: String,
    label: 'Subject',
    max: 140
  },
  website: {
    type: String,
    label: 'Website',
    regEx: SimpleSchema.RegEx.Url
  }
}).extend(BaseContactFormSchema);
