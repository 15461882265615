import React from 'react';
import classNames from 'classnames';
import { Col } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'components/Elements/Img';

export default class FeaturedProduct extends React.Component {
  render () {
    const propClasses = this.props.className;
    const propButtonClasses = this.props.btnClassName;
    const classes = classNames(
      'card-featured',
      'product',
      {
        dark: this.props.dark,
        light: this.props.light
      },
      ...propClasses
    );
    const btnClasses = classNames(
      'btn',
      {
        [`btn-outline-${this.props.btnColor ? this.props.btnColor : `gray-warm-${this.props.dark ? '200' : '800'}`}`]: true
      },
      ...propButtonClasses
    );
    const link = `/products/${this.props.productLine.slug}/${this.props.slug}`;
    return (
      <article
        className={classes}
        itemScope
        itemType="http://schema.org/Product"
        style={{
          backgroundSize: `cover`,
          backgroundImage: `url(${`img/header-bg-gd.jpg`})`,
          backgroundRepeat: `none`,
          backgroundPosition: `center`,
        }}
      >
        {/* 
        <div className={`ft-bg-image d-none d-md-block`}>
          <div>
            <Img
              title={`${this.props.productLine} ${this.props.name}`}
              alt={`${this.props.productLine} ${this.props.name}`}
              fixed={this.props.thumbnail.fixed}
              style={{
                height: `280px`,
                top: `0.75em`,
                width: `100%`
              }}
              imgStyle={{
                top: `50%`,
                transform: `translate3D(-50%,-50%,0)`,
                left: `50%`
              }}
              objFit={`contain`}
              fadeIn
            />
          </div>
        </div>
         */}
        <Col className="card-content">
          <div className={`d-block mb-10em`}>
            <Img
              title={`${this.props.productLine.name} ${this.props.name}`}
              alt={`${this.props.productLine.name} ${this.props.name}`}
              fixed={this.props.thumbnail.fixed}
              style={{
                maxWidth: `100%`,
                height: `280px`,
                top: `0.75em`,
                width: `100%`,
              }}
              imgStyle={{
                top: `50%`,
                transform: `translate3D(-50%,-50%,0)`,
                left: `50%`,
              }}
              objFit={`contain`}
              fadeIn
            />
          </div>
          <h1 className={`lh-10 mb-5em`} itemProp="name">
            {this.props.name && (
              <span className={`ls--30em fs-40px`}>
                <span
                  className={`${
                    this.props.productLine === 'TAHOMA'
                      ? `fw-800`
                      : `fw-700`
                  } mr-3em`}
                >
                  {this.props.productLine.name}
                </span>
                <span className={`fw-500`}>{this.props.name}</span>
              </span>
            )}
            <br />
            {this.props.tagline && (
              <span className={`fw-300 fs-28px ls--30em`} style={{display: "inline-block", padding: "8px 0"}}>
                {this.props.tagline}
              </span>
            )}
          </h1>
          {this.props.summary && (
            <p className={`mb-15em`} itemProp="description">
              {this.props.summary.summary}
            </p>
          )}
          <Link
            to={link}
            className={btnClasses}
            title={`${this.props.productLine.name} ${this.props.name}`}
            itemProp="url"
          >
            Learn More
          </Link>
        </Col>
      </article>
    )
  }
}
