import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

export default class CardNews extends React.Component {
  render () {
    const article = this.props;
    const propClasses = article.className;
    const classes = classNames(
      'card-news',
      'news',
      ...propClasses
    );
    return (
      <article className={classes} itemScope itemType="http://schema.org/BlogPosting">
        <div className="card-content">
          {
            article.categories && (
              article.categories.length > 0 && article.categories.map((item) => {
                return (
                  <span key={`categories-${item.slug}`}>
                    <Link
                      to={item.slug ? `/media?category=${item.slug}` : '/media'}
                      className={`category color-cyan-400 category-${item.slug}`}
                    >
                      {item.name}
                    </Link>
                  </span>
                );
              })
            )
          }
          <h1 itemProp="name" className={`fs-11em ff-base lh-14 fw-600 ls-10em mb-5em`}>
            <Link rel="bookmark" title="Title Description" itemProp="url" to={article.slug ? `/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}` : '/media'} className={`color-gray-cold-700 color-black-hover`}>
              {article.title}
            </Link>
          </h1>
          {article.excerpt ? (
            <summary itemProp="description"><p className={`mb-15em`}>{article.excerpt.excerpt}</p></summary>
          ) : (
            article.content && <summary itemProp="description"><p className={`mb-15em`}>{article.content.childMarkdownRemark.excerpt}</p></summary>
          )}
          <dl>
            <dd>
              <time itemProp="datePublished">
                {article.createdAtLegacy ? article.createdAtLegacy : article.createdAt}
              </time>
            </dd>
          </dl>
        </div>
      </article>
    );
  }
}
CardNews.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string
};
CardNews.defaultProps = {
  className: ''
};
