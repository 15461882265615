import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'components/Elements/Img';

export default class FeaturedCase extends React.Component {
  render () {
    const propClasses = this.props.className;
    const propButtonClasses = this.props.btnClassName;
    function invertColor (h, bw) {
      let hex = h;
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return `# ${padZero(r)} ${padZero(g)} ${padZero(b)}`;
    }
    const color = this.props.color && invertColor(this.props.color, true);
    const classes = classNames(
      'card-case',
      'case',
      {
        first: this.props.first,
        dark: color === `#000000`,
        light: color === `#ffffff`,
        [`bg-${this.props.first ? `image` : `gradient-${this.props.gradient ? this.props.gradient : `gray-warm`}`}`]: true
      },
      ...propClasses
    );
    const btnClasses = classNames(
      'btn',
      `mt-20em`,
      {
        [`btn-outline-${color ? `${color === `#FFFFFF` ? `gray-warm-100` : `gray-warm-900`}` : `gray-warm-${this.props.color === `#000000` ? '200' : '800'}`}`]: true
      },
      ...propButtonClasses
    );

    const style = {
      color,
      backgroundColor: `${this.props.color}`,
      // backgroundImage: this.props.first ? `url(${this.props.thumbnail})` : `${color === `#FFFFFF` ? `url(http://dev.pawa.rocks/apantac/img/gradient.png)` : `url(http://dev.pawa.rocks/apantac/img/gradient-light.png)`}`,
      backgroundSize: `cover`,
      backgroundRepeat: `none`,
      backgroundPosition: `center`
    };
    let excerpt;
    if (this.props.excerpt) {
      const excerptTrim = this.props.excerpt.excerpt.substring(0, 140);
      const excerptLastIndex = excerptTrim.lastIndexOf(` `);
      excerpt = excerptTrim.substring(0, excerptLastIndex);
    }

    return (
      <article className={classes} style={style}>
        {this.props.first ? <div className={`bg-container`} style={{ backgroundImage: `url(${this.props.banner && this.props.banner.fluid.src})` }} /> : <div className={`bg-container overlay`} />}
        <div className="card-content">
          {this.props.logo &&
            <Img
              title={this.props.title}
              alt={this.props.title}
              fixed={this.props.logo.fixed}
              className={`mb-10em logo-fit logo-h80`}
              fadeIn
            />
          }
          {excerpt && (<p className={`mb-15em`}>{`${excerpt}${this.props.excerpt.length > 140 ? `...` : ``}`}</p>)}
          <a href={this.props.slug ? `/media/articles/${this.props.categories ? this.props.categories[0].slug : `c`}/${this.props.slug}` : `/media?category=case-studies`} className={btnClasses}>Read More</a>
        </div>
      </article>
    );
  }
}
FeaturedCase.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  color: PropTypes.string,
  btnClassName: PropTypes.string,
  thumbnail: PropTypes.string,
  banner: PropTypes.string,
  first: PropTypes.bool,
  gradient: PropTypes.string,
  className: PropTypes.string
};
FeaturedCase.defaultProps = {
  thumbnail: '/img/default-featuredCase-tumbnail.jpg',
  banner: '/img/default-featuredCase-banner.jpg',
  className: '',
  btnClassName: '',
  color: '',
  first: true,
  gradient: ''
};
