import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { countries } from 'country-data';
import { parsePhoneNumber } from 'libphonenumber-js';

export default class CardContact extends React.Component {
  render () {
    const propClasses = this.props.className;
    const classes = classNames(
      'card-contact',
      'contact',
      ...propClasses
    );
    return (
      <article className={classes} itemScope itemType="http://schema.org/LocalBusiness">
        <div className="card-content">
          <div className="mb-10em">
            {this.props.filter != 'general' && this.props.filter != 'support' && this.props.title && <h1>{this.props.title}</h1>}
            {this.props.contact && <div className={`contactName`} itemProp="name">{this.props.contact}</div>}
            {this.props.filter != 'support' && this.props.position && <div className={`position`} itemProp="name">{this.props.position}</div>}
            {this.props.filter === 'support' && this.props.title && <div className={`position`} itemProp="name">{this.props.title}</div>}
            {this.props.contactTitle && <div className={`contactTitle`} itemProp="contactTitle">{this.props.contactTitle}</div>}
            {this.props.company && <span itemProp="name">{this.props.company}</span>}
            {this.props.company && <br />}
          </div>
          <div>
            {this.props.address && (
              <address itemScope itemType="http://schema.org/PostalAddress" className={`fs-09em`}>
                {this.props.address.address.address1 && <span itemProp="streetAddress">
                  {this.props.address.address.address1}{this.props.address.address.address1 && this.props.address.address.address2 && (<br />)}{this.props.address.address.address2 && this.props.address.address.address2}
                </span>}
                {this.props.address.address.address1 && <br />}
                {this.props.address.address.city && <span itemProp="addressLocality">{this.props.address.address.city}</span>}
                {this.props.address.address.city && this.props.address.address.state && <span>, </span>}
                {this.props.address.address.state && <span itemProp="addressRegion">{this.props.address.address.state}</span>}
                {this.props.address.address.state && this.props.address.address.postalCode && ` `}
                {this.props.address.address.postalCode && <span itemProp="postalCode">{this.props.address.address.postalCode}</span>}
                {this.props.address.address.state ? <br /> : this.props.address.address.postalCode && <br />}
                {this.props.address.address.country &&
                  <span itemProp="addressCountry">
                    {
                      countries[this.props.address.address.country].alpha3 !== `USA`
                        ? countries[this.props.address.address.country].name
                        : countries[this.props.address.address.country].alpha3
                    }
                  </span>
                }
                {this.props.openingHours && <meta itemprop="openingHours"  style={{display: `none`}}  datetime="Mo,Tu,We,Th,Fr 01:00-19:00" />}
              </address>
            )}
            {this.props.geo &&
              <div>
                {this.props.geo.latitude && this.props.geo.longitude &&
                  <div itemType="http://schema.org/GeoCoordinates" itemScope="" itemProp="geo">
                    <meta itemProp="latitude" content={this.props.geo.latitude} />
                    <meta itemProp="longitude" content={this.props.geo.longitude} />
                  </div>
                }
              </div>
            }
            {this.props.phones &&
              <div className={`${this.props.fax && `${this.props.fax.length > 1 || this.props.phones.length > 1 && `mb-5em`}`}`}>
                { this.props.phones.length > 1 && this.props.phones[0].number && <div className={`fs-08em`}>Phone{this.props.phones.length > 1 && `s`}</div> }
                { this.props.phones && this.props.phones.map((phone) => {
                  if (phone.number) {
                    const number = `+${phone.number.replace(/[- )(+]/g, '').replace(/^0+/, '')}`;
                    const phoneNumberParse = parsePhoneNumber(number);
                    const phoneNumber = phoneNumberParse.format(`INTERNATIONAL`);
                    return (
                      phone.number && <div key={`phone-${phone.number}`} className={`fs-09em`}>{this.props.phones.length < 2 && `Phone: `}<span itemProp="telephone">{phoneNumber}</span></div>
                    );
                  }
                  return ``;
                })}
              </div>
            }
            {this.props.fax &&
              <div className={`${this.props.emails && `${this.props.emails.length > 1 || this.props.fax.length > 1|| this.props.phones.length > 1 && `mb-5em`}`}`}>
                { this.props.fax.length > 1 && this.props.fax[0].number && <div className={`fs-08em`}>Fax</div> }
                { this.props.fax && this.props.fax.map((fax) => {
                  if (fax.number) {
                    const number = `+${fax.number.replace(/[- )(+]/g, '').replace(/^0+/, '')}`;
                    const phoneNumberParse = parsePhoneNumber(number);
                    const phoneNumber = phoneNumberParse.format(`INTERNATIONAL`);
                    return (
                      fax.number && <div key={`fax-${fax.number}`} className={`fs-09em`}>{this.props.fax.length < 2 && `Fax: `}<span itemProp="faxNumber">{phoneNumber}</span></div>
                    );
                  }
                  return ``;
                })}
              </div>
            }
            {this.props.emails &&
              <div className={`mb-5em`}>
                { this.props.emails.length > 1 && this.props.emails[0].address && <div className={`fs-08em`}>Emails</div> }
                { this.props.emails && this.props.emails.map((email) => {
                  return (
                    email.address && <div key={`email-${email.address}`} className={`fs-09em`}>{this.props.emails.length < 2 && `Email: `}<a href={`mailto:${email.address}`} itemProp="email">{email.address}</a></div>
                  );
                })}
              </div>
            }
            {this.props.socialProfiles &&
              <div className={`mb-5em`}>
                { this.props.socialProfiles && this.props.socialProfiles.map((network) => {
                  let url;
                  if (network.label) {
                    url = network.label.toLowerCase() === `skype` ? `skype:${network.address}?add` : network.address;
                  }
                  return (
                    network.address && <div key={`network-${network.address}`} className={`fs-09em`}><i className={`socicon socicon-${network.label.toLowerCase()}`} /> <a href={`${url}`} itemProp="network">{network.address}</a></div>
                  );
                })}
              </div>
            }
            {this.props.url && <div className={`fs-09em`}><b><a href={this.props.url} target="_blank" itemProp="url" title={this.props.url}>{this.props.url.replace(/^(https?:\/\/)?(www\.)?/,'').replace(/\/$/, "")}</a></b></div>}
          </div>
        </div>
      </article>
    );
  }
}
CardContact.propTypes = {
  // thumbnail: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  category: PropTypes.arrayOf(String),
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  web: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    number: PropTypes.string
  })),
  fax: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    number: PropTypes.string
  })),
  emails: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    address: PropTypes.string
  })),
  socialProfiles: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    address: PropTypes.string
  })),
  contacts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string
  })),
  geo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  openingHours: PropTypes.string,
  position: PropTypes.string,
};
CardContact.defaultProps = {
  // thumbnail: '/img/default-contact-tumbnail.jpg',
  company: '',
  position: '',
  contact: '',
  openingHours: '',
  className: ''
};
