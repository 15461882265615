import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'components/Elements/Img';

export default class FeaturedNews extends React.Component {
  render () {
    const article = this.props;
    const propClasses = article.className;
    const propButtonClasses = article.btnClassName;
    const classes = classNames(
      'card-featured',
      'news',
      ...propClasses
    );
    const btnClasses = classNames(
      'btn',
      'mb-20em',
      'color-gray-cold-400',
      'color-gray-cold-600-hover',
      {
        [`btn-outline-${article.color ? article.color : `gray-warm-${article.dark ? '200' : '200'}`}`]: true
      },
      ...propButtonClasses
    );
    const bgDefault = article.thumbnail ? `white` : `indigo-900`;
    let excerpt = ``;
    if (article.excerpt) {
      if (article.excerpt.excerpt) {
        excerpt = article.excerpt.excerpt;
      }
    } else if (article.content) {
      if (article.content.childMarkdownRemark.excerpt) {
        excerpt = article.content.childMarkdownRemark.excerpt;
      }
    }
    return (
      <article className={classes}>
        <Col sm={8} className={`col`}>
          <div className="card-content">
            {
              article.categories && (
                article.categories.length > 0 && article.categories.map((item) => {
                  return (
                    <span key={`categories-${item.slug}`}>
                      <Link
                        to={item.slug ? `/media?category=${item.slug}` : '/media'}
                        className={`category color-cyan-400 category-${item.slug}`}
                      >
                        {item.name}
                      </Link>
                    </span>
                  );
                })
              )
            }
            <hr />
            <h1 className={`fs-17em lh-12 fw-500 ls--30em mb-5em`}>
              <Link to={`/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}`} className={`color-gray-cold-700 color-black-hover`}>
                {article.title}
              </Link>
            </h1>
            {excerpt && <p className={`mb-15em`}>{excerpt}</p>}
            <dl>
              <dd>
                <time itemProp="datePublished">
                  {article.createdAtLegacy ? article.createdAtLegacy : article.createdAt}
                </time>
              </dd>
            </dl>
            <Link to={`/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}`} className={btnClasses}>Read More</Link>
          </div>
        </Col>
        <Col sm={4} className={`col d-none d-md-block thumbnail ${!article.color && `bg-${bgDefault}`}`} style={{ background: article.color }}>
          { article.banner && (
            <Img
              title={article.title}
              alt={article.title}
              fluid={article.banner.fluid}
              className={`mb-10em`}
              style={{
                position: `absolute`,
                top: `0`,
                left: `0`,
                height: `100%`,
                width: `100%`
              }}
              imgStyle={{
                top: `50%`
              }}
              fadeIn
            />
          )}
          { !article.banner && article.thumbnail ? (
            <Img
              title={article.title}
              alt={article.title}
              sizes={article.thumbnail.fluid}
              style={{
                position: `absolute`,
                top: `0`,
                left: `0`,
                height: `100%`,
                width: `100%`
              }}
              imgStyle={{
                top: `50%`,
                transform: `translate3D(-50%,-50%,0)`,
                left: `50%`
              }}
              objFit={`contain`}
              fadeIn
            />
          ) : (
            article.logo && (
              <Img
                title={article.title}
                alt={article.title}
                fluid={article.logo.fixed}
                className={`mb-10em`}
                style={{
                  position: `absolute`,
                  top: `0`,
                  left: `0`,
                  height: `100%`,
                  width: `100%`
                }}
                imgStyle={{
                  top: `50%`,
                  maxHeight: `120px`
                }}
                objFit={`contain`}
                fadeIn
              />
            )
          )}
        </Col>
      </article>
    );
  }
}
// FeaturedNews.propTypes = {
//   thumbnailCover: PropTypes.bool,
//   thumbnail: PropTypes.string,
//   bgColor: PropTypes.string,
//   btnColor: PropTypes.string,
//   categories: PropTypes.arrayOf(PropTypes.shape({
//     slug: PropTypes.string,
//     name: PropTypes.string
//   })).isRequired,
//   title: PropTypes.string.isRequired,
//   // excerpt: PropTypes.string.isRequired,
//   slug: PropTypes.string.isRequired,
//   dark: PropTypes.bool,
//   btnClassName: PropTypes.string,
//   className: PropTypes.string
// };
// FeaturedNews.defaultProps = {
//   // thumbnail: '/img/default-featuredCase-tumbnail.jpg',
//   thumbnailCover: false,
//   bgColor: '',
//   className: '',
//   btnClassName: '',
//   dark: false,
//   btnColor: ''
// };
