import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Layout from 'components/Layout';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';
import LoadingView from 'components/Loaders/LoadingView';

import { Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import { CardContact } from 'components/Cards/Cards';
import SimpleHeader from 'components/Headers/SimpleHeader';
// import MediaFilters from 'components/Navigation/MediaFilters';

import ModalContainer from 'components/CTAs/ModalContainer';

import FormContact from 'components/CTAs/FormContact';
import FormContactSales from 'components/CTAs/FormContactSales';
import FormContactSupport from 'components/CTAs/FormContactSupport';
import FormContactRMA from 'components/CTAs/FormContactRMA';
import FormContactPR from 'components/CTAs/FormContactPR';
import FormContactResellers from 'components/CTAs/FormContactResellers';

class Template extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql('data', data);
    if (
      !data ||
      !data.contentfulPage ||
      !data.allContentfulContacts
    ) { return <LoadingView />; }
    const gutter = 8;

    const generalContacts = [];
    const salesContacts = [];
    const supportContacts = [];
    const prContacts = [];
    const qaContacts = [];
    const page = data.contentfulPage;

    data.allContentfulContacts.edges.forEach((node) => {
      const contact = node.node;
      logger.verbose(`contact`, contact);
      if (contact.category.some(e => e.slug === `general`)) {
        generalContacts.push(contact);
      }
      if (contact.category.some(e => e.slug === `direct-sales`)) {
        salesContacts.push(contact);
      }
      if (contact.category.some(e => e.slug === `technical-support`)) {
        supportContacts.push(contact);
      }
      if (contact.category.some(e => e.slug === `pr`)) {
        prContacts.push(contact);
      }
      if (contact.category.some(e => e.slug === `qa`)) {
        qaContacts.push(contact);
      }
    });
    logger.templateEnd();

    return (
      <Layout>
        <Fluid>
          <SEO
            title={page.title}
            pathname={`/company/contact`}
          />
          <SimpleHeader title={page.title} />
          <section className={`ptb-20em`}>
            <Offset>
              <Row id={`general-contact`}>
                <Col lg={2} className={`mb-20em`}>
                  <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitleGeneral ? page.template.contactTitleGeneral : `General Contact`}</h1>
                  { generalContacts && generalContacts.map((contact) => {
                    return (
                      <CardContact key={contact.slug} {...contact} filter={`general`} />
                    );
                  })}
                  <ModalContainer label={`Contact Us`}>
                    <FormContact />
                  </ModalContainer>
                </Col>
                <Col lg={{ size: 8, offset: 1 }}>
                  <Row className={`mb-20em`} id={`technical-support`}>
                    <Col xs={12} className={`mb-10em`}>
                      <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitleTechnicalSupport ? page.template.contactTitleTechnicalSupport : `Contact Technical Support`}</h1>
                      <p>{page.template.contactHeadingTechnicalSupport && page.template.contactHeadingTechnicalSupport}</p>
                    </Col>
                    <Col md={4} className={`mb-10em`}>
                      <p className={`mb-20em`}>
                        <b>Technical Support Hours:</b><br />
                        <span className={`fs-09em`}>
                          Monday - Friday<br />
                          7:00 AM  to  5:00 PM (PT)<br />
                        </span>
                      </p>
                      <p className={`mb-20em`}>
                        <b>Technical Support Assistance:</b><br />
                        <span className={`fs-09em`}>
                          Phone: +1 (503) 968-3000 <br />
                          Email: <a href={`mailto: support@Apantac.com`}>support@Apantac.com</a>
                        </span>
                      </p>
                    </Col>
                    { supportContacts && supportContacts.map((contact) => {
                      return (
                        <Col key={contact.slug} md={6} lg={4} className={`prl-${gutter}px pb-${gutter}px`}>
                          <CardContact {...contact} filter={`support`} />
                        </Col>
                      );
                    })}
                    <Col xs={12} className={`mb-10em`}>
                      <ModalContainer label={`Online Inquiry`}>
                        <FormContactSupport />
                      </ModalContainer>
                      <Link
                        to={`/support`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        title={`Support Center`}
                        itemProp="url"
                      >
                        Support Center
                      </Link>
                      <a
                        href={`http://www.apantac.net/osticket/`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        alt={`Support Request Ticket`}
                        target={`_blank`}
                        itemProp="url"
                      >
                        Support Request Ticket
                      </a>
                      <a
                        href={`http://www.apantac.net/rma/`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        alt={`Support Request Ticket`}
                        target={`_blank`}
                        itemProp="url"
                      >
                        RMA Request
                      </a>
                      {/*
                      <ModalContainer label={`RMA Request`}>
                        <FormContactRMA />
                      </ModalContainer>
                      */}
                    </Col>
                  </Row>
                  <Row className={`mb-20em`} id={`sales-contact`}>
                    <Col xs={12} className={`mb-10em`}>
                      <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitleSales ? page.template.contactTitleSales : `Contact Sales`}</h1>
                      <p>{page.template.contactHeadingSales && page.template.contactHeadingSales}</p>
                    </Col>
                    { salesContacts && salesContacts.map((contact) => {
                      return (
                        <Col key={contact.slug} md={6} lg={4} className={`prl-${gutter}px pb-${gutter}px`}>
                          <CardContact {...contact} />
                        </Col>
                      );
                    })}
                    <Col xs={12} className={`mb-10em`}>
                      <ModalContainer label={`Online Inquiry`}>
                        <FormContactSales />
                      </ModalContainer>
                      <Link
                        to={`/buy`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        title={`Find a Distributor`}
                        itemProp="url"
                      >
                        Find a Distributor
                      </Link>
                    </Col>
                  </Row>
                  <Row className={`mb-20em`} id={`partners-resellers`}>
                    <Col xs={12} className={`mb-10em`}>
                      <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitleResellers ? page.template.contactTitleResellers : `Partners & Resellers Contact`}</h1>
                      <p>{page.template.contactHeadingResellers && page.template.contactHeadingResellers}</p>
                    </Col>
                    <Col xs={12} className={`mb-10em`}>
                      <Link
                        to={`/buy`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        title={`Contact Support`}
                        itemProp="url"
                      >
                        Find a Distributor
                      </Link>
                      <ModalContainer label={`Become a Partner`}>
                        <FormContactResellers />
                      </ModalContainer>
                    </Col>
                  </Row>
                  <Row className={`mb-20em`} id={`pr`}>
                    <Col xs={12} className={`mb-10em`}>
                      <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitlePR ? page.template.contactTitlePR : `Press Contact`}</h1>
                      <p>{page.template.contactHeadingPR && page.template.contactHeadingPR}</p>
                    </Col>
                    { prContacts && prContacts.map((contact) => {
                      return (
                        <Col key={contact.slug} md={6} lg={4} className={`prl-${gutter}px pb-${gutter}px`}>
                          <CardContact {...contact} />
                        </Col>
                      );
                    })}
                    <Col xs={12} className={`mb-10em`}>
                      <ModalContainer label={`Press Inquiry`}>
                        <FormContactPR />
                      </ModalContainer>
                      {/*
                      <Link
                        to={`/support`}
                        className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
                        title={`Support Center`}
                        itemProp="url"
                      >
                        Media Guidelines
                      </Link>
                      */}
                    </Col>
                  </Row>
                  {/*
                  <Row className={`mb-20em`} id={``}>
                    <Col xs={12} className={`mb-10em`}>
                      <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1 lh-12`}>{page.template.contactTitleRMA ? page.template.contactTitleRMA : `Quality Assurance & Warranties`}</h1>
                      <p>{page.template.contactHeadingRMA && page.template.contactHeadingRMA}</p>
                    </Col>
                    { qaContacts && qaContacts.map((contact) => {
                      return (
                        <Col key={contact.slug} md={6} lg={4} className={`prl-${gutter}px pb-${gutter}px`}>
                          <CardContact {...contact} />
                        </Col>
                      );
                    })}
                    <Col xs={12} className={`mb-10em`}>
                      <ModalContainer label={`RMA Request`}>
                        <FormContactRMA />
                      </ModalContainer>
                    </Col>
                  </Row>
                  */}
                </Col>
              </Row>

            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}
export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      id
      title
      template{
        contactTitleGeneral
        contactTitleTechnicalSupport
        contactTitleSales
        contactTitleResellers
        contactTitlePR
        contactTitleRMA
        contactHeadingTechnicalSupport
        contactHeadingSales
        contactHeadingResellers
        contactHeadingPR
        contactHeadingRMA
      }
    }
    allContentfulContacts(sort: {fields: [sort], order: DESC}) {
      edges {
        node {
          id
          ... Contact
        }
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["contact"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
  }
`;

export default Template
