import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logger from 'utils/logger';
import Img from 'components/Elements/Img';
import { countries, lookup } from 'country-data';
import { parsePhoneNumber } from 'libphonenumber-js';

export default class CardDistributor extends React.Component {
  render () {
    const propClasses = this.props.className;
    const classes = classNames(
      'card-distributor',
      'distributor',
      ...propClasses
    );
    let i = 0;
    logger.verbose(`CardDistributor > this.props`, this.props);
    const { address } = this.props.address;
    let { country } = address;
    if (countries[address.country]) {
      country = countries[address.country].alpha3 !== `USA` && countries[address.country].alpha3;
    }
    let addressCountry = country.split(`,`)[0];
    if (country.length < 4) {
      logger.verbose(`CardDistributor > country < 4`, country);
      let ctr = null;
      if (country.length === 2) { ctr = lookup.countries({ alpha2: country })[0] };
      if (country.length === 3) { ctr = lookup.countries({ alpha3: country })[0] };
      if (ctr) { addressCountry = ctr.name.split(`,`)[0]; }
      logger.verbose(`CardDistributor > country < 4 lookup ctr`, ctr);
      logger.verbose(`CardDistributor > country < 4 lookup country`, country);
    }
    logger.verbose(`CardDistributor > country`, country);
    logger.verbose(`CardDistributor > addressCountry`, addressCountry);

    return (
      <article className={classes} itemScope itemType="http://schema.org/LocalBusiness">
        <div className="card-content">
          <div className="thumbnail text-center mb-5em pos-relative" style={{ height: `110px` }}>
            {this.props.logo &&
              <Img
                title={this.props.title}
                alt={this.props.title}
                fluid={this.props.logo.fixed}
                className={`mtb-10em`}
                style={
                  {
                    height: `80px`,
                    overflow: `visible`,
                    maxWidth: `100%`,
                    paddingTop: `1em`,
                    paddingBottom: `1em`
                  }
                }
                imgStyle={
                  {
                    top: `50%`,
                    left: `50%`,
                    transform: `translate3D(-50%,-50%,0)`,
                    maxWidth: `80%`
                  }
                }
                objFit={`contain`}
                fadeIn
              />
            }
          </div>
          {this.props.company && <h1 itemProp="name" className={`lh-12 pb-5em`}>{this.props.company}</h1>}
          <div className="description" itemProp="description">
            <h2>
              { this.props.serviceCountries && this.props.serviceCountries.map((country) => {
                i += 1;
                return (
                  <span key={`countries-${this.props.id}-${country}`}>
                    {this.props.serviceCountries.length > 1 ? `${this.props.serviceCountries.length === i ? ` and ` : ``}` : ``}
                    {countries[country].name.split(`,`)[0]}
                    {this.props.serviceCountries.length > 2 ? `${this.props.serviceCountries.length !== i && this.props.serviceCountries.length - 1 !== i ? `, ` : ``}` : ``}
                  </span>
                );
              })}
            </h2>
            {address && (
              <address itemScope itemType="http://schema.org/PostalAddress" className={`fs-09em`}>
                {address.address1 &&
                  <span itemProp="streetAddress">
                    {address.address1}
                    {address.address2 &&
                      [
                        <br />,
                        <span>{address.address2}</span>
                      ]
                    }
                  </span>
                }
                {address.city &&
                  [
                    <br />,
                    <span itemProp="addressLocality">{address.city}</span>
                  ]
                }
                {address.city && address.state && <span>, </span>}
                {address.state && <span itemProp="addressRegion">{address.state}</span>}
                {address.state && address.postalCode && <span> </span>}
                {address.city && address.postalCode && !address.state && <span>, </span>}
                {address.postalCode && <span itemProp="postalCode">{address.postalCode}</span>}
                {addressCountry &&
                  [
                    <br />,
                    <span itemProp="addressCountry">{addressCountry}</span>
                  ]
                }
                {this.props.openingHours &&
                  <meta itemProp="openingHours" style={{ display: `none` }} dateTime="Mo,Tu,We,Th,Fr 01:00-19:00" />
                }
              </address>
            )}
            {this.props.geo &&
              <div>
                {this.props.geo.latitude && this.props.geo.longitude &&
                  <div itemType="http://schema.org/GeoCoordinates" itemScope="" itemProp="geo">
                    <meta itemProp="latitude" content={this.props.geo.latitude} />
                    <meta itemProp="longitude" content={this.props.geo.longitude} />
                  </div>
                }
              </div>
            }
            {this.props.phones &&
              <div className={`${this.props.fax && `${this.props.fax.length > 1 || this.props.phones.length > 1 && `mb-5em`}`}`}>
                { this.props.phones.length > 1 && this.props.phones[0].number && <div className={`fs-08em`}>Phone{this.props.phones.length > 1 && `s`}</div> }
                { this.props.phones && this.props.phones.map((phone) => {
                  if (phone.number) {
                    const number = `+${phone.number.replace(/[- )(+]/g, '').replace(/^0+/, '')}`;
                    const phoneNumberParse = parsePhoneNumber(number);
                    const phoneNumber = phoneNumberParse.format(`INTERNATIONAL`);
                    return (
                      phone.number && <div key={`phone-${phone.number}`} className={`fs-09em`}>{this.props.phones.length < 2 && `Phone: `}<span itemProp="telephone">{phoneNumber}</span></div>
                    );
                  }
                  return ``;
                })}
              </div>
            }
            {this.props.fax &&
              <div className={`${this.props.emails && `${this.props.emails.length > 1 || this.props.fax.length > 1 || this.props.phones.length > 1 && `mb-5em`}`}`}>
                { this.props.fax.length > 1 && this.props.fax[0].number && <div className={`fs-08em`}>Fax</div> }
                { this.props.fax && this.props.fax.map((fax) => {
                  if (fax.number) {
                    const number = `+${fax.number.replace(/[- )(+]/g, '').replace(/^0+/, '')}`;
                    const phoneNumberParse = parsePhoneNumber(number);
                    const phoneNumber = phoneNumberParse.format(`INTERNATIONAL`);
                    return (
                      fax.number && <div key={`fax-${fax.number}`} className={`fs-09em`}>{this.props.fax.length < 2 && `Fax: `}<span itemProp="faxNumber">{phoneNumber}</span></div>
                    );
                  }
                  return ``;
                })}
              </div>
            }
            {this.props.emails &&
              <div className={`mb-5em`}>
                { this.props.emails.length > 1 && this.props.emails[0].address && <div className={`fs-08em`}>Emails</div> }
                { this.props.emails && this.props.emails.map((email) => {
                  return (
                    email.address && <div key={`email-${email.address}`} className={`fs-09em`}>{this.props.emails.length < 2 && `Email: `}<a href={`mailto:${email.address}`} itemProp="email">{email.address}</a></div>
                  );
                })}
              </div>
            }
            {/*
              {this.props.contact && <div>{this.props.contact}</div>}
            */}
            {this.props.url && <div className={`fs-09em`}><b><a href={this.props.url} target="_blank" itemProp="url" title={this.props.url}>{this.props.url.replace(/^(https?:\/\/)?(www\.)?/,'').replace(/\/$/, "")}</a></b></div>}
          </div>
        </div>
      </article>
    );
  }
}
CardDistributor.propTypes = {
  logo: PropTypes.string,
  openingHours: PropTypes.string,
  id: PropTypes.string.isRequired,
  serviceCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  company: PropTypes.string,
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
  url: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    number: PropTypes.string
  })),
  fax: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    number: PropTypes.string
  })),
  emails: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    address: PropTypes.string
  })),
  contacts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string
  })),
  geo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  className: PropTypes.string
};
CardDistributor.defaultProps = {
  logo: 'http://via.placeholder.com/320x110?text=logo',//'/img/default-distributor-tumbnail.jpg',
  tags: [],
  className: ''
};
