import React from 'react';
import logger from 'utils/logger';
import AutoForm from 'uniforms-unstyled/AutoForm';
import TextField from 'uniforms-material/TextField';
import LongTextField from 'uniforms-material/LongTextField';
// import BoolField from 'uniforms-material/BoolField';
// import SelectField from 'uniforms-material/SelectField';
import HiddenField from 'uniforms-unstyled/HiddenField';
import SubmitField from 'uniforms-unstyled/SubmitField';
import ErrorsField from 'uniforms-material/ErrorsField'; // Choose your theme package.
import { SupportContactFormSchema } from 'api/schemas/ContactFormSchema';

// import { TextFieldDark } from 'layouts/jss';
import { Container, Row, Col } from 'reactstrap';

function encode (data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join(`&`);
}

const formName = `Technical Support`;
const formLabel = `Online Inquiry`;
const formSchema = SupportContactFormSchema;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      form: {},
      error: false,
      success: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (key, value) {
    logger.info('key', key, 'value', value);
    logger.verbose(`this`, this);
    let mutateForm = this.state.form;
    mutateForm[key] = value;

    this.setState({
      form: mutateForm
    });
  }

  handleSubmit (doc) {
    logger.group(__filename, '>', ' handleSubmit (doc)');
    logger.verbose(`this`, this);
    // ... handle here
    const encoded = encode({
      "form-name": formName,
      ...doc
    });
    logger.debug('encoded', encoded);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encoded
    }).then(() => {
      this.setState({
        success: true,
        error: false
      });
    }).catch((error) => {
      logger.verbose(error);
      this.setState({
        success: false,
        error: true
      });
    });

    logger.groupEnd();
  }

  render () {
    logger.file(__filename, '>', 'render()');
    logger.verbose('formSchema', formSchema);

    logger.fileEnd();

    return (
      <Container>
        {this.state.success ? (
          <Row>
            <Col>
              <h1>Thank You!</h1>
              <hr />
              <div className="lead fs-15em fw-300">
                <p>
                  Your message has been successfully sent.
                  We will send you a reply as soon as possible.
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <AutoForm
            schema={formSchema}
            showInlineError
            validate="onChangeAfterSubmit"
            onChange={(key, value) => {
              this.handleChange(key, value);
            }}
            onSubmit={this.handleSubmit}

            name={formName}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="botField"
            data-subject={`${formName} ${formLabel} from <${this.state.form.email}>`}
          >
            <Row>
              <Col xs={12}>
                <h1 className={`color-gray-cold-800`}>{formLabel}</h1>
                <hr />
              </Col>
            </Row>
            <Row className={`mb-0em`}>
              <Col xs={12}><h2 className={`fs-12em mt-05em fw-400 mb-0`}>Contact Information</h2></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="name" /></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="email" /></Col>
            </Row>
            <Row className={`mb-0em`}>
              <Col md={6} lg={4}><TextField className={`mui`} name="jobTitle" /></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="phone" /></Col>
            </Row>
            <Row className={`mb-20em`}>
              <Col md={6} lg={4}><TextField className={`mui`} name="company" /></Col>
            </Row>
            <Row className={`mb-10em`}>
              <Col xs={12}><h2 className={`fs-12em mt-05em fw-400 mb-0`}>Inquiry Details</h2></Col>
              <Col lg={8}><TextField className={`mui`} name="subject" /></Col>
              <Col lg={8}><TextField className={`mui`} name="product" /></Col>
              <Col lg={8}><LongTextField className={`mui`} name="inquiry" rows={4} rowsMax={6} /></Col>
            </Row>
            <Row>
              <Col xs={12}>
                {this.state.error && <div>There was an error processing your message, please verify the information or try again latter.</div>}
                <ErrorsField />
              </Col>
            </Row>
            <Row className={`mb-10em`}>
              <Col md={6} lg={4}>
                <HiddenField name="botField" />
                <SubmitField
                  value="Send"
                  className={`btn btn-primary-500 btn-md`}
                />
              </Col>
            </Row>
          </AutoForm>
        )}
      </Container>
    );
  }
}
