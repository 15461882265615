import React from 'react';
import logger from 'utils/logger';
import Modal from 'react-modal';

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal () {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal () {
    logger.info(`afterOpenModal`);
    logger.verbose(`this`, this);
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal () {
    this.setState({ modalIsOpen: false });
  }

  render () {
    logger.file(__filename, '>', 'render()');

    logger.fileEnd();
    logger.info(`modalIsOpen:`, this.state.modalIsOpen);

    return (
      <frag>
        <button
          onClick={this.openModal}
          className={`mr-10em btn btn-outline-gray-cold-300 color-gray-cold-500 color-gray-cold-600-hover tt-none ls-10em ptb-6em fs-09em fw-500`}
          title={`Contact Support`}
        >
          {this.props.label}
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className="modal-form"
          overlayClassName="modal-form-overlay"
          contentLabel={this.props.label}
        >
          <button onClick={this.closeModal} className={`close`} />
          {this.props.children}
        </Modal>
      </frag>
    );
  }
}
