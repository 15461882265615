import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logger from 'utils/logger';

export default class CardProduct extends React.Component {
  render () {
    logger.component(__filename, '>', 'render()');
    logger.info('this.props', this.props);
    const propClasses = this.props.className;
    const classes = classNames(
      'card-product',
      'product',
      ...propClasses
    );
    const product = this.props.product;
    logger.componentEnd();
    return (
      <article className={classes} itemScope itemType="http://schema.org/Product">
        <div className="card-content">
          <a href={/*this.props.slug*/`/product?id=${this.props.id}`} itemProp="url" title={this.props.name}>
            <div className="thumbnail mb-10em">
              <img src={this.props.thumbnail ? this.props.thumbnail : `/img/default-product-tumbnail.jpg`} alt={this.props.name} itemProp="image" />
            </div>
            <h1 itemProp="name" className={`lh-12 pb-5em`}>{this.props.productLine} <br/><span className="fs-085em fw-400">{this.props.name}</span></h1>
          </a>
          <div className="description" itemProp="description">
            <p className="lead">{this.props.tagline}</p>
            <p>{this.props.summary}</p>
          </div>
          <div className="tags">
            { this.props.tags && this.props.tags.map((tag) => {
              return (
                <span
                  key={`tag-${this.props.id}-${tag}`}
                  className={`tag ${tag}`}
                >
                  <span>{tag}</span>
                </span>
              );
            })}
          </div>
        </div>
      </article>
    );
  }
}
CardProduct.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(String),
  className: PropTypes.string
};
CardProduct.defaultProps = {
  thumbnail: '/img/default-product-tumbnail.jpg',
  tags: [],
  className: ''
};
